<template>
  <div id="templatesParametros">
    <div :class="templates.length > 0 ? 'text-right' : 'text-center'">
      <q-btn
        flat
        round
        class="q-ml-md"
        icon="settings"
        color="primary"
      >
        <q-menu>
          <q-list>
            <q-item clickable @click="importarTemplates">
              <q-item-section>
                Importar Templates Modelo
              </q-item-section>
            </q-item>
            <q-item clickable @click="configDomain">
              <q-item-section>
                Configurar Domínio de E-mail
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>

    <template v-if="templates.length > 0">
      <q-table
        title
        :data="templates"
        :columns="columns"
        row-key="id"
        :separator="separator"
        :loading="listandoTemplate"
        no-data-label="Nenhum resultado encontrado."
        :pagination.sync="pagination"
      >
        <template v-slot:bottom>
          <div></div>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
            <q-td key="id" class="text-right">{{props.row.id}}</q-td>
            <q-td key="padrao"  class="text-center">
              <q-icon
                v-if="props.row.padrao"
                name="check"
                size="24px"
                class="text-positive">
                <q-tooltip
                  max-width='400px'
                  :content-style="{fontSize:'15px'}"
                  >
                Esse template será usado por padrão caso não tenha selecionado nenhum ao enviar.
                </q-tooltip>
              </q-icon>
            </q-td>
            <q-td key="subject">{{props.row.subject}}</q-td>
            <q-td key="tipo">{{capitalize(props.row.tipo)}}</q-td>
            <q-td key="from_email">{{props.row.from_email}}</q-td>
            <q-td v-if="$q.platform.is.desktop" class="tdfora">
              <div class="divfora">
                <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                  <q-btn
                    @click="duplicateTemplate(props.row)"
                    size="12px"
                    class="bg-secondary text-white"
                    icon="fa fa-copy"
                  >
                    <q-tooltip>
                      Duplicar Template
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="EditTemplate(props.row)"
                    size="12px"
                    class="bg-primary text-white"
                    icon="edit"
                  >
                    <q-tooltip>
                      Editar Template
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="deleteTemplate(props.row)"
                    size="12px"
                    class="bg-negative text-white"
                    icon="delete_forever"
                  >
                    <q-tooltip>
                      Excluir Template
                    </q-tooltip>
                  </q-btn>
                </div>
              </div>
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" :props="props">
            <q-td colspan="100%">
              <div class="q-py-sm q-gutter-sm">
                <q-btn
                  @click="duplicateTemplate(props.row)"
                  size="12px"
                  class="bg-secondary text-white"
                  icon="fa fa-copy"
                >
                  <q-tooltip>
                    Duplicar Template
                  </q-tooltip>
                </q-btn>
                <q-btn
                  @click="EditTemplate(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                >
                  <q-tooltip>
                    Editar Template
                  </q-tooltip>
                </q-btn>
                <q-btn
                  @click="deleteTemplate(props.row)"
                  size="12px"
                  class="bg-negative text-white"
                  icon="delete_forever"
                >
                  <q-tooltip>
                    Excluir Template
                  </q-tooltip>
                </q-btn>
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
      <q-btn
        @click="ativarPage()"
        :label="'Carregar mais ' + pagingTemplate.page + '/' + pagingTemplate.pageCount"
        class="absolute-bottom-right text-white bg-primary"
        style="margin: 0 25px 15px 0"
        :disable="pagingTemplate.page >= pagingTemplate.pageCount || listandoTemplate"
        :loading="listandoTemplate"
      >
        <template v-slot:loading>
          <q-spinner color="white" size="1em" />
        </template>
      </q-btn>
    </template>

    <modal-config-domain-email />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Dialog, Loading, format } from "quasar";

export default {
  name: "templates-parametros",
  data() {
    return {
      separator: "horizontal",
      pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: row => row.id,
          sortable: true
        },
        {
          name: "padrao",
          align: "center",
          label: "Padrão",
          field: row => row.padrao,
          sortable: true
        },
        {
          name: "subject",
          label: "Assunto",
          field: row => row.subject,
          sortable: true,
          align: "left"
        },
        {
          name: "tipo",
          label: "Tipo",
          field: row => row.tipo,
          sortable: true,
          align: "left"
        },
        {
          name: "from_email",
          label: "De",
          field: row => row.from_email,
          sortable: true,
          align: "left"
        },
        {
          name: "tdFora",
          label: "",
        },
      ],
      counter: 1
    };
  },
  methods: {
    importarTemplates() {
      Dialog.create({
        title: "Confirma a importação dos templates modelo?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
          axios
            .post("/templates/edit?importar=1")
            .then(res => {
              if (!res.data.success) {
                this.$q.notify({
                  color: "negative",
                  message: "Não foi possível importar os templates.",
                  position: "top-right",
                });
                Loading.hide();
                return;
              }

              Loading.hide();
              this.$q.notify({
                message: res.data.message,
                position: "top-right",
                color: "positive",
                icon: "check"
              });

              this.$store.dispatch("resetPagingTemplate");
              this.$store.dispatch("listarTemplate");
            })
            .catch(error => {
              Loading.hide();
              this.$q.notify({
                message: "Não foi possível importar os templates.",
                position: "top-right",
                color: "negative",
                icon: "warning"
              });
            });
        });
    },
    configDomain() {
      axios
        .get("/templates/config")
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message || "Erro ao buscar configurações",
              position: "top-right",
              color: "negative",
            });
            return;
          }

          this.$store.commit("emailDomainConfig", res.data.options);
          this.$store.commit("openDialogDomainConfig", true);
      })
      .catch(error => {
        this.$q.notify({
          message: error.message || "Erro ao buscar configurações",
          position: "top-right",
          color: "negative"
        });
      });
    },
    ativarPage() {
      this.counter += 1;
      this.$store.dispatch("listarTemplate", this.counter);
    },
    deleteTemplate(value) {
      Dialog.create({
        title: "Tem certeza que deseja excluir este template de e-mail?",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("/templates/delete/" + value.id)
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-positive text-white",
                  ok: {
                    "text-color": "green",
                    color: "white"
                  }
                });
                this.$store.dispatch("resetPagingTemplate");
                this.$store.dispatch("listarTemplate");
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
            })
            .catch(() => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    EditTemplate(value) {
      this.$store.dispatch("editarTemplateGet", value);
    },
    duplicateTemplate(value) {
      this.$store.dispatch("duplicateTemplate", value);
    },
    qtdsTemplates(){
      return this.templates.length + ' de ' + this.pagingTemplate.count;
    },
    capitalize(value) {
      return format.capitalize(value);
    },
  },
  computed: {
    ...mapGetters([
      "templates",
      "pagingTemplate",
      "totalTemplate",
      "listandoTemplate"
    ])
  },
  mounted() {
    this.$store.dispatch("listarTemplate");
  },
  watch:{
    counter(valorNovo){
      if(valorNovo >= this.pagingTemplate.pageCount){
        this.counter = 1;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
