import axios from "@/axios-auth";
import { Notify } from "quasar";
import despesas from "./despesas";

const state = {
  planodecontas: [],
  planoContas: [],
  OpenDialogNovoPlanoContas: false,
  tiposcd: {},
  tiposat: {},
  tiposvof: {},
  loadingPlanodecontas: false,
};

const mutations = {
  planodecontas(state, userData) {
    state.planodecontas = userData;
  },
  planoContas: (state, userData) => {
    state.planoContas = userData;
  },
  OpenDialogNovoPlanoContas: (state, userData) => {
    state.OpenDialogNovoPlanoContas = userData;
  },
  tiposcd: (state, userData) => {
    state.tiposcd = userData;
  },
  tiposat: (state, userData) => {
    state.tiposat = userData;
  },
  tiposvof: (state, userData) => {
    state.tiposvof = userData;
  },
  setLoadingPlanodecontas: (state, userData) => {
    state.loadingPlanodecontas = userData;
  },
};

const actions = {
  planoContas({ commit }, userData) {
    let url = "/plano-contas";
    let param = {
      cd: ""
    };

    if (userData === "all") {
      param.cd = "";
    } else if (despesas.state.callDespesa) {
      param.cd = "D";
    } else {
      param.cd = "C";
    }

    if (userData && userData !== "all") {
      param.cd = userData;
    }

    axios
      .get(url, {
        params: param
      })
      .then(res => {
        if (res.data.success) {
          commit("planoContas", res.data.planodecontas);
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  planodecontas({ commit }) {
    commit("setLoadingPlanodecontas", true);

    axios.get("/plano-contas?tree=1").then(res => {
      commit("setLoadingPlanodecontas", false);
      if (res.data.success) {
        commit("planodecontas", res.data.planodecontas);
        commit("tiposcd", res.data.tipocd);
        commit("tiposat", res.data.tipoat);
        commit("tiposvof", res.data.tipovof);
      }
    }).catch(() => {
      commit("setLoadingPlanodecontas", false);
    });
  }
};

const getters = {
  planodecontas(state) {
    return state.planodecontas;
  },
  planoContas: state => {
    return state.planoContas;
  },
  OpenDialogNovoPlanoContas: state => {
    return state.OpenDialogNovoPlanoContas;
  },
  tiposcd: state => {
    return state.tiposcd;
  },
  tiposat: state => {
    return state.tiposat;
  },
  tiposvof: state => {
    return state.tiposvof;
  },
  loadingPlanodecontas: state => {
    return state.loadingPlanodecontas;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
