import axios from "@/axios-auth";
import { Notify } from "quasar";

const state = {
  templates: [],
  pagingTemplate: {
    finder: null,
    page: 0,
    current: 0,
    count: 0,
    perPage: 0,
    prevPage: false,
    nextPage: true,
    pageCount: 0,
    sort: null,
    direction: false,
    limit: null,
    sortDefault: false,
    directionDefault: false,
    scope: null
  },
  totalTemplate: 0,
  templateData: {
    body: "",
    bodyWhatsapp: "",
    subject: "",
    tipo: "",
    from_name: "",
    from_email: "",
    replyto_name: "",
    replyto_email: "",
    return_path: "",
    padrao: false,
    enviar_boleto_anexo: false,
    enviar_extrato_anexo: false,
  },
  config: {
    lang: "pt-br"
  },
  errorsTemplate: [],
  salvandoTemplate: false,
  listandoTemplate: false,
  isNewTemplate: true,
  idTemplate: null,
  openDialogNovoTemplate: false,
  openDialogDomainConfig: false,
  emailDomainConfig: null
};

const mutations = {
  templates: (state, userData) => {
    state.templates = userData.templates;
  },
  pagingTemplate: (state, userData) => {
    state.pagingTemplate.finder = userData.finder;
    state.pagingTemplate.page = userData.page;
    state.pagingTemplate.current = userData.current;
    state.pagingTemplate.count = userData.count;
    state.pagingTemplate.perPage = userData.perPage;
    state.pagingTemplate.prevPage = userData.prevPage;
    state.pagingTemplate.nextPage = userData.nextPage;
    state.pagingTemplate.pageCount = userData.pageCount;
    state.pagingTemplate.sort = userData.sort;
    state.pagingTemplate.direction = userData.direction;
    state.pagingTemplate.limit = userData.limit;
    state.pagingTemplate.sortDefault = userData.sortDefault;
    state.pagingTemplate.directionDefault = userData.directionDefault;
    state.pagingTemplate.scope = userData.scope;
  },
  totalTemplate: (state, userData) => {
    state.totalTemplate = userData.total;
  },
  templateData: (state, userData) => {
    state.templateData = userData;
  },
  errorsTemplate: (state, userData) => {
    state.errorsTemplate = userData;
  },
  salvandoTemplate: (state, userData) => {
    state.salvandoTemplate = userData;
  },
  isNewTemplate: (state, userData) => {
    state.isNewTemplate = userData.isNewTemplate;
  },
  idTemplate: (state, userData) => {
    state.idTemplate = userData.id;
  },
  listandoTemplate: (state, userData) => {
    state.listandoTemplate = userData;
  },
  openDialogNovoTemplate: (state, userData) => {
    state.openDialogNovoTemplate = userData;
  },
  openDialogDomainConfig: (state, userData) => {
    state.openDialogDomainConfig = userData;
  },
  emailDomainConfig: (state, userData) => {
    state.emailDomainConfig = userData;
  }
};

const actions = {
  resetPagingTemplate: ({ commit }) => {
    commit("templates", {
      templates: []
    });
    commit("pagingTemplate", {
      finder: null,
      page: 0,
      current: 0,
      count: 0,
      perPage: 0,
      prevPage: false,
      nextPage: true,
      pageCount: 0,
      sort: null,
      direction: false,
      limit: null,
      sortDefault: false,
      directionDefault: false,
      scope: null
    });
    commit("totalTemplate", {
      total: 0
    });
  },
  listarTemplate: ({ commit }, userData) => {
    if (state.listandoTemplate) return;
    let dados = {};
    if (userData) {
      dados.page = userData;
    } else {
      dados.page = 1;
    }
    if (!state.pagingTemplate.nextPage) {
      return;
    }
    commit("listandoTemplate", true);
    let error = false;

    axios
      .get("/templates", {
        params: dados
      })
      .then(res => {
        if (res.data.success) {
          commit("templates", {
            templates: state.templates.concat(res.data.data.templates)
          });
          commit("pagingTemplate", {
            finder: res.data.data.paging.finder,
            page: res.data.data.paging.page,
            current: res.data.data.paging.current,
            count: res.data.data.paging.count,
            perPage: res.data.data.paging.perPage,
            prevPage: res.data.data.paging.prevPage,
            nextPage: res.data.data.paging.nextPage,
            pageCount: res.data.data.paging.pageCount,
            sort: res.data.data.paging.sort,
            direction: res.data.data.paging.direction,
            limit: res.data.data.paging.limit,
            sortDefault: res.data.data.paging.sortDefault,
            directionDefault: res.data.data.paging.directionDefault,
            scope: res.data.data.paging.scope
          });
          commit("totalTemplate", {
            total: state.totalTemplate + state.pagingTemplate.pageCount
          });
        }
        commit("listandoTemplate", false);
      })
      .catch(() => {
        error = true;
        commit("listandoTemplate", false);
      });
      if(error) {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      }

  },
  salvarTemplate: ({ commit, dispatch }, userData) => {
    commit("salvandoTemplate", true);
    axios
      .post("/templates/add", userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          dispatch("resetPagingTemplate");
          dispatch("listarTemplate");
          commit("templateData", {
            body: "",
            bodyWhatsapp: "",
            subject: "",
            tipo: "",
            from_name: "",
            from_email: "",
            replyto_name: "",
            replyto_email: "",
            return_path: "",
            padrao: false,
            enviar_boleto_anexo: false,
            enviar_extrato_anexo: false,
          });
          commit("errorsTemplate", []);
          commit("openDialogNovoTemplate", false);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
          commit("errorsTemplate", res.data.errors);
        }
        commit("salvandoTemplate", false);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("salvandoTemplate", false);
      });
  },
  editarTemplateGet: ({ commit }, userData) => {
    commit("isNewTemplate", {
      isNewTemplate: false
    });
    commit("idTemplate", {
      id: userData.id
    });
    commit("templateData", {
      body: "",
      bodyWhatsapp: "",
      subject: "",
      tipo: "",
      from_name: "",
      from_email: "",
      replyto_name: "",
      replyto_email: "",
      return_path: "",
      padrao: false,
      enviar_boleto_anexo: false,
      enviar_extrato_anexo: false,
    });
    commit("errorsTemplate", []);
    axios
      .get("/templates/edit/" + userData.id)
      .then(res => {
        if (res.data.success) {
          commit("templateData", {
            body: res.data.template.body,
            bodyWhatsapp: res.data.template.body,
            subject: res.data.template.subject,
            tipo: res.data.template.tipo,
            from_name: res.data.template.from_name,
            from_email: res.data.template.from_email,
            replyto_name: res.data.template.replyto_name,
            replyto_email: res.data.template.replyto_email,
            return_path: res.data.template.return_path,
            padrao: res.data.template.padrao,
            enviar_boleto_anexo: res.data.template.enviar_boleto_anexo,
            enviar_extrato_anexo: res.data.template.enviar_extrato_anexo,
          });
          commit("openDialogNovoTemplate", true);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  editarTemplatePost: ({ commit, dispatch }, userData) => {
    commit("salvandoTemplate", true);
    axios
      .post("/templates/edit/" + state.idTemplate, userData)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          dispatch("resetPagingTemplate");
          dispatch("listarTemplate");
          commit("templateData", {
            body: "",
            bodyWhatsapp: "",
            subject: "",
            tipo: "",
            from_name: "",
            from_email: "",
            replyto_name: "",
            replyto_email: "",
            return_path: "",
            padrao: false,
            enviar_boleto_anexo: false,
            enviar_extrato_anexo: false,
          });
          commit("errorsTemplate", []);
          commit("openDialogNovoTemplate", false);
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
        commit("salvandoTemplate", false);
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        commit("salvandoTemplate", false);
      });
  },
  duplicateTemplate: ({ dispatch }, userData) => {

    axios
      .post(`/templates/edit/${userData.id}?duplicar=1`)
      .then(res => {
        if (res.data.success) {
          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          dispatch("resetPagingTemplate");
          dispatch("listarTemplate");
        } else {
          Notify.create({
            message: res.data.message,
            color: "orange",
            position: "top-right"
          });
        }
      });
  },
  clearTemplate: ({ commit }) => {
    commit("isNewTemplate", {
      isNewTemplate: true
    });
    commit("templateData", {
      body: "",
      bodyWhatsapp: "",
      subject: "",
      tipo: "",
      from_name: "",
      from_email: "",
      replyto_name: "",
      replyto_email: "",
      return_path: "",
      padrao: false,
      enviar_boleto_anexo: false,
      enviar_extrato_anexo: false,
    });
    commit("errorsTemplate", []);
  }
};

const getters = {
  templateData: state => {
    return state.templateData;
  },
  config: state => {
    return state.config;
  },
  errorsTemplate: state => {
    return state.errorsTemplate;
  },
  salvandoTemplate: state => {
    return state.salvandoTemplate;
  },
  templates: state => {
    return state.templates;
  },
  pagingTemplate: state => {
    return state.pagingTemplate;
  },
  totalTemplate: state => {
    return state.totalTemplate;
  },
  isNewTemplate: state => {
    return state.isNewTemplate;
  },
  listandoTemplate: state => {
    return state.listandoTemplate;
  },
  openDialogNovoTemplate: state => {
    return state.openDialogNovoTemplate;
  },
  openDialogDomainConfig: state => {
    return state.openDialogDomainConfig;
  },
  emailDomainConfig: state => {
    return state.emailDomainConfig;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
