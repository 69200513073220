<template>
  <div id="openDialogDomainConfig">
    <q-dialog v-model="openDialogDomainConfig" persistent>
      <q-card style="max-width: 600px; width: 100%">
        <q-card-section class="flex justify-between">
          <div class="text-h6">Configurações de Domínio</div>
          <q-btn flat dense round v-close-popup icon="close" @click="closeModal" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-gutter-md">
          <q-select
            v-model="emailType"
            :options="emailTypeOptions"
            color="primary"
            clearable
            label="Remetente"
            option-label="label"
            option-value="value"
            emit-value
            map-options
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">Sem Resultados</q-item-section>
              </q-item>
            </template>
          </q-select>

          <!-- Domínio Dourasoft -->
          <template v-if="emailType === 'dourasoft'">
            <div class="text-caption q-mb-sm">
              O remetente dos e-mails será nome@dourasoft.net.
            </div>
          </template>

          <!-- Domínio Próprio -->
          <template v-if="emailType === 'own_domain'">
            <q-input v-model="domain" label="Domínio" />
            <div class="text-caption">
              O remetente dos e-mails será nome@{{domain}}.
            </div>

            <q-separator />

            <q-card-section v-if="showCnameRecords" class="">
              <div class="text-subtitle2 q-mb-md">Adicione os seguintes registros CNAME no seu DNS:</div>

              <div v-for="(record, index) in cnameRecords" :key="index" class="row" >
                <q-input v-model="record.name" label="Nome" readonly class="col-6" >
                  <template v-slot:append>
                    <q-btn flat round size="sm" icon="content_copy" @click="copyToClipboard(record.name)">
                      <q-tooltip>Copiar nome</q-tooltip>
                    </q-btn>
                  </template>
                </q-input>
                <q-input v-model="record.value" label="Valor" readonly class="col-6">
                  <template v-slot:append>
                    <q-btn flat round size="sm" icon="content_copy" @click="copyToClipboard(record.value)">
                      <q-tooltip>Copiar valor</q-tooltip>
                    </q-btn>
                  </template>
                </q-input>
              </div>
            </q-card-section>
          </template>

          <!-- SMTP Externo -->
          <template v-if="emailType === 'external_smtp'">
            <form autocomplete="off">
            <div class="text-caption text-negative">
              Nota: Nesse modo não é possível identificar o recebimento e leitura dos e-mails.
            </div>
            <q-input v-model="smtp.callerIdentity" label="Nome ou callerIdentity" />
            <q-input v-model="smtp.remetente" label="E-mail do Remetente" />
            <q-input v-model="smtp.host" label="Servidor SMTP" />
            <q-input v-model="smtp.port" label="Porta" type="number" />
            <q-input v-model="smtp.username" label="Usuário" autocomplete="off" spellcheck="false" />
            <q-input v-model="smtp.password" label="Senha" autocomplete="off" spellcheck="false" />
            </form>
          </template>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-primary">
          <q-btn :loading="loading" no-wrap no-caps clickable color="positive" label="Salvar" @click="save" />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/axios-auth';
import { Loading } from 'quasar';

export default {
  name: 'modal-config-domain-email',
  data() {
    return {
      loading: false,
      emailType: 'dourasoft',
      emailTypeOptions: [
        {
          label: 'Usar o domínio dourasoft.net',
          value: 'dourasoft',
        },
        {
          label: 'Usar meu próprio domínio',
          value: 'own_domain',
        },
        {
          label: 'Usar servidor SMTP externo',
          value: 'external_smtp',
        },
      ],
      domain: '',
      smtp: {
        remetente: '',
        callerIdentity: '',
        host: '',
        port: '',
        username: '',
        password: '',
      },
      showCnameRecords: false,
      cnameRecords: [],
    };
  },
  methods: {
    closeModal() {
      this.$store.commit('openDialogDomainConfig', false);
      this.resetForm();
    },
    resetForm() {
      this.emailType = 'dourasoft';
      this.domain = '';
      this.smtp = {
        remetente: '',
        callerIdentity: '',
        host: '',
        port: '',
        username: '',
        password: '',
      };
    },
    validate() {
      if (this.emailType === 'own_domain' && !this.domain) {
        this.$q.notify({
          message: 'Por favor, insira o domínio',
          color: 'negative',
          position: 'top-right',
        });
        return false;
      }

      if (this.emailType === 'external_smtp') {
        if (!this.smtp.remetente || !this.smtp.callerIdentity || !this.smtp.host || !this.smtp.port || !this.smtp.username || !this.smtp.password) {
          this.$q.notify({
            message: 'Por favor, preencha todos os campos SMTP',
            color: 'negative',
            position: 'top-right',
          });
          return false;
        }
      }

      return true;
    },
    checkCredentials() {
      if (this.emailDomainConfig && this.emailDomainConfig.templates_email_domain_type) {
        this.emailType = this.emailDomainConfig.templates_email_domain_type;
      }
      if (this.emailDomainConfig && this.emailDomainConfig.templates_email_domain) {
        this.domain = this.emailDomainConfig.templates_email_domain;
      }
      if (this.emailDomainConfig && this.emailDomainConfig.cnameRecords) {
        this.cnameRecords = this.emailDomainConfig.cnameRecords;
        this.showCnameRecords = true;
      }
      if (this.emailDomainConfig && this.emailDomainConfig.smtp) {
        this.smtp = this.emailDomainConfig.smtp;
      }
    },
    save() {
      if (!this.validate()) return;

      this.loading = true;
      Loading.show();

      const payload = {
        type: this.emailType,
        ...(this.emailType === 'own_domain' && { domain: this.domain }),
        ...(this.emailType === 'external_smtp' && { smtp: this.smtp }),
      };

      axios
        .post('/templates/config', payload)
        .then(res => {
          if (!res.data.success) {
            throw new Error(res.data.message || 'Erro ao salvar configurações');
          }

          this.$q.notify({
            message: 'Configurações salvas com sucesso',
            position: 'top-right',
            color: 'positive',
          });

          if (this.emailType === 'own_domain') {
            this.cnameRecords = res.data.cnameRecords;
            this.showCnameRecords = true;
            return;
          }

          this.closeModal();
        })
        .catch(error => {
          this.$q.notify({
            message: error.message || 'Erro ao salvar configurações',
            position: 'top-right',
            color: 'negative',
            icon: 'warning',
          });
        })
        .finally(() => {
          this.loading = false;
          Loading.hide();
        });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$q.notify({
          message: 'Copiado para a área de transferência',
          color: 'positive',
          position: 'top-right',
          timeout: 2000
        });
      });
    }
  },
  computed: {
    ...mapGetters([
      'openDialogDomainConfig',
      'emailDomainConfig'
    ]),
  },
  watch: {
    openDialogDomainConfig: {
      immediate: true,
      handler(value) {
        if (value) {
          this.checkCredentials();
        }
      },
    },
  },
};
</script>
