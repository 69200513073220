<template>
  <q-dialog v-model="openDialog" persistent>
    <q-card class="scroll" style="width: 90%; max-width: 400px">
      <q-card-section class="row items-center no-wrap">
        <div class="text-h6">Reajuste de recorrências em massa</div>
        <q-space />
        <q-btn icon="close" clickable flat round dense v-close-popup @click="fecharModal()" />
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-md">
        <div class="row q-col-gutter-x-md">
          <div class="col-12">
                <q-select
                  emit-value
                  map-options
                  bottom-slots
                  label="Operação"
                  :options="opcoesOperacao"
                  :error="Boolean(erros.operacao)"
                  v-model="operacao"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(erro, i) in erros.operacao" :key="erro + i">{{erro}}</span>
                  </template>
                </q-select>
          </div>

          <div class="col-12">
            <q-field
                        v-model="valor"
                        label="Valor"
                        bottom-slots
                        :error="Boolean(erros.valor)"
                        style="width: 100%"
                      >
                        <template v-slot:before>
                          <q-btn
                            small
                            size="md"
                            style="width: 49px"
                            :label="tipoValor === '%' ? '%' : 'R$'"
                            v-model="tipoValor"
                            @click="toggleTipoValor()"
                          />
                        </template>

                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="tipoValor === '%' ? percentFormat : moneyFormat"
                            v-show="floatingLabel"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span
                            v-for="(erro, i) in erros.valor"
                            :key="erro + i"
                            >{{ erro }}</span
                          >
                        </template>
                      </q-field>
          </div>

          <div class="col-12 q-col-gutter-x q-mb-sm">
            <q-select
              emit-value
              map-options
              bottom-slots
              label="Reajuste de Itens"
              :options="opcoesReajusteItems"
              :error="Boolean(erros.reajusteItems)"
              v-model="reajusteItems"
            >
              <template v-slot:error>
                <span v-for="(erro, i) in erros.reajusteItems" :key="erro + i">{{erro}}</span>
              </template>
            </q-select>
          </div>

          <p class="text-caption text-grey-7">Para recorrências sem itens, o valor total é atualizado diretamente. Para recorrências com itens, cada item é reajustado e o total é recalculado.</p>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          v-ripple
          clickable
          label="Salvar"
          color="positive"
          text-color="white"
          style="margin-right: 22px; margin-bottom: 10px"
          :loading="salvando"
          @click="reajustar"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { Loading } from 'quasar';
import { Money } from 'v-money';

import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'modal-reajuste-recorrencias-massa',
  components: { Money },
  data() {
    return {
      // Modal
      openDialog: false,
      // Formulário
      operacao: 'adicionar',
      reajusteItems: 'todos',
      tipoValor: '%',
      valor: 0.00,
      erros: {},
      // Salvar
      salvando: false,
      // Opções
      opcoesOperacao: [
        {
          label: 'Adicionar',
          value: 'adicionar',
        },
        {
          label: 'Subtrair',
          value: 'subtrair',
        },
      ],
      opcoesReajusteItems: [
        {
          label: 'Todos os itens',
          value: 'todos',
        },
        {
          label: 'Item de maior valor',
          value: 'maior-valor',
        },
      ],
      // Informações de recorrências selecionadas
      ids: [],
      filtros: {},
      selecionarTodas: false,
      // Máscaras
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    // Modal
    abrirModal({ ids, filtros, selecionarTodas }) {
      this.ids = JSON.parse(JSON.stringify(ids));
      this.filtros = JSON.parse(JSON.stringify(filtros));
      this.selecionarTodas = selecionarTodas;

      this.operacao = 'adicionar';
      this.tipoValor = '%';
      this.valor = 0.00;
      this.resetarErros();

      this.openDialog = true;
    },
    fecharModal() {
      this.openDialog = false;
    },
    // Formulário
    resetarErros() {
      this.erros = {};
    },
    // Salvar
    reajustar() {
      const data = {
        acao: 'reajuste',
        ids: this.ids,
        all: this.selecionarTodas,
        operacao: this.operacao,
        reajuste_items: this.reajusteItems,
        tipo_valor: this.tipoValor,
        valor: this.valor,
      };

      this.salvando = true;

      axios
        .post('/recorrencias/edit-multiple', data, { params: this.filtros })
        .then(res => {
          this.salvando = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          if (!res.data.success) {
            this.erros = res.data.errors;
            this.$q.dialog({
              title: res.data.message,
              class: 'bg-warning text-white',
              ok: {
                'text-color': 'orange',
                color: 'white',
              },
            });
            return;
          }

          this.$q.dialog({
            title: 'Deseja realmente alterar o valor das recorrências?',
            message: res.data.message,
            cancel: 'Cancelar',
            persistent: true,
          }).onOk(() => {
            this.salvando = true;

            data.confirmar = true;

            axios
              .post('/recorrencias/edit-multiple', data, { params: this.filtros })
              .then(res => {
                this.salvando = false;

                if (res.error && res.error.response.status === 403) {
                  this.notificarSemPermissao(res.error.response.data.message);
                  return;
                }

                this.$q.dialog({
                  title: res.data.message,
                  class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                  ok: {
                    'text-color': res.data.success ? 'green' : 'orange',
                    color: 'white',
                  },
                });

                if (!res.data.success) {
                  this.erros = res.data.errors;
                  return;
                }

                this.openDialog = false;
                this.$emit('atualizar');
              });
          });
        })
        .catch(() => {
          this.salvando = false;

          this.$q.dialog({
            title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
            class: 'bg-negative text-white',
            ok: {
              'text-color': 'red',
              color: 'white',
            },
          });
        });
    },
    // Toggle
    toggleTipoValor() {
      this.tipoValor = this.tipoValor === '%' ? '$' : '%';
    },
    // Utilidade
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
  },
  mounted() {
    bus.$on('abrirModalReajusteRecorrenciasMassa', this.abrirModal);
  },
};
</script>
